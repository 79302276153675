import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
   <path d="M5185 10239 c-640 -24 -1282 -187 -1893 -479 -328 -158 -653 -364
-942 -599 -201 -164 -482 -439 -640 -627 -96 -115 -125 -150 -191 -239 -414
-554 -701 -1190 -853 -1885 -22 -98 -34 -168 -71 -405 -3 -16 -12 -106 -20
-200 -27 -305 -15 -767 26 -1046 6 -41 15 -102 20 -137 11 -78 62 -321 87
-422 150 -587 433 -1185 788 -1665 612 -827 1443 -1428 2412 -1745 342 -112
673 -180 1077 -221 155 -16 695 -16 845 0 603 64 1089 194 1600 427 90 42 281
139 338 173 29 17 55 31 57 31 12 0 278 171 378 242 258 185 441 343 682 589
529 540 915 1189 1149 1931 317 1009 285 2138 -90 3131 -243 643 -585 1186
-1054 1671 -406 420 -831 729 -1355 986 -739 362 -1503 521 -2350 489z m500
-84 c233 -11 476 -45 750 -105 650 -141 1270 -424 1811 -825 120 -89 133 -100
203 -160 26 -22 76 -65 112 -95 80 -67 333 -321 414 -414 33 -38 65 -74 70
-80 6 -6 38 -45 71 -86 248 -310 474 -675 626 -1015 366 -818 497 -1672 393
-2555 -57 -477 -188 -943 -392 -1395 -53 -116 -136 -277 -224 -435 -68 -122
-285 -434 -405 -585 -166 -207 -506 -548 -704 -706 -228 -181 -426 -318 -609
-421 -299 -169 -472 -249 -806 -371 -542 -200 -1208 -299 -1799 -268 -357 19
-683 65 -936 132 -41 11 -109 30 -150 41 -156 42 -363 115 -608 217 -192 80
-482 235 -697 374 -70 45 -303 212 -358 256 -231 186 -555 502 -697 680 -19
25 -40 49 -45 55 -25 26 -197 259 -254 344 -444 665 -708 1402 -793 2212 -17
165 -17 714 0 890 7 74 24 203 38 285 14 83 30 175 35 205 16 98 107 442 148
560 178 516 341 837 659 1295 164 238 471 576 717 790 50 43 104 90 120 104
94 83 432 323 552 392 183 106 254 145 360 197 262 131 599 257 868 327 77 20
156 40 175 46 58 15 329 67 415 78 189 25 272 33 441 40 174 8 251 7 499 -4z"/>
<path d="M4627 7884 c-4 -4 11 -39 32 -78 21 -39 60 -111 86 -161 26 -49 67
-125 91 -167 24 -43 44 -80 44 -83 0 -3 11 -22 23 -43 25 -39 70 -121 212
-387 24 -44 58 -107 77 -140 18 -33 47 -87 63 -120 17 -33 33 -62 36 -65 16
-13 60 -113 52 -120 -7 -8 -193 -113 -328 -186 -35 -19 -61 -40 -63 -51 -2
-14 101 -215 172 -333 9 -14 25 -44 38 -67 22 -43 35 -51 53 -34 6 5 44 27 85
49 41 22 124 67 184 101 60 33 112 61 116 61 3 0 13 -12 21 -27 8 -16 16 -30
19 -33 3 -3 17 -27 31 -55 14 -27 69 -129 122 -227 53 -97 97 -181 97 -187 0
-6 4 -11 9 -11 5 0 12 -8 16 -19 3 -10 41 -85 85 -165 44 -81 80 -149 80 -151
0 -2 14 -28 31 -57 17 -29 47 -82 66 -118 20 -36 47 -85 61 -110 13 -25 43
-79 65 -120 71 -131 129 -213 207 -291 158 -158 333 -242 586 -280 65 -10 101
-10 175 0 130 18 257 53 330 91 71 37 176 94 184 100 6 5 33 20 60 35 157 86
245 137 249 144 7 10 -8 38 -152 295 -62 109 -112 202 -112 206 0 4 -6 13 -14
19 -16 13 -53 -5 -296 -144 -85 -48 -168 -69 -244 -61 -32 3 -64 10 -70 15 -6
4 -23 11 -37 14 -14 4 -41 20 -60 37 -19 16 -37 30 -40 30 -9 0 -98 141 -134
210 -38 74 -66 123 -75 135 -4 6 -28 48 -52 95 -24 47 -51 96 -60 110 -8 14
-35 61 -58 105 -23 44 -46 85 -50 90 -4 6 -29 51 -55 100 -62 115 -232 425
-244 445 -34 53 -72 134 -69 147 2 8 35 32 73 52 73 39 83 44 302 167 73 41
137 74 142 74 5 0 11 3 13 8 2 4 17 15 33 24 17 9 31 24 33 32 3 13 -70 151
-180 341 -16 28 -34 60 -40 73 -6 12 -18 22 -27 22 -9 0 -64 -26 -121 -59 -58
-32 -127 -71 -155 -86 -27 -15 -114 -64 -193 -107 -79 -44 -144 -79 -146 -77
-2 2 -23 40 -46 84 -24 44 -51 91 -60 105 -9 14 -31 54 -50 90 -18 36 -65 121
-103 190 -103 184 -175 315 -220 400 -22 41 -48 87 -59 103 l-19 27 -408 0
c-224 0 -411 -3 -414 -6z"/>
<path d="M4394 7482 c-5 -4 -33 -50 -61 -102 -47 -89 -62 -116 -133 -245 -59
-107 -89 -162 -111 -205 -13 -25 -26 -47 -29 -50 -3 -3 -26 -43 -50 -90 -24
-47 -73 -139 -110 -205 -60 -108 -92 -167 -172 -315 -14 -25 -36 -65 -50 -90
-14 -25 -47 -85 -73 -135 -26 -49 -51 -94 -55 -100 -4 -5 -26 -46 -49 -90 -23
-44 -46 -87 -52 -95 -11 -19 -134 -243 -198 -365 -26 -49 -69 -127 -94 -173
-26 -45 -47 -87 -47 -92 0 -6 -4 -10 -10 -10 -5 0 -10 -4 -10 -10 0 -8 -31
-67 -142 -270 -29 -53 -74 -135 -216 -395 -36 -66 -76 -139 -89 -163 -42 -77
-70 -72 380 -72 221 0 412 -3 424 -6 32 -9 62 34 156 221 16 33 49 94 72 135
23 41 68 125 100 185 31 61 66 125 77 143 l20 32 959 0 c1068 0 982 -6 934 70
-14 22 -25 42 -25 45 0 3 -20 40 -43 83 -60 105 -124 224 -164 303 -63 122 20
109 -698 109 -457 0 -625 3 -625 11 0 6 29 64 64 128 34 64 92 170 126 236 35
66 117 219 182 339 65 121 118 223 118 227 0 4 3 9 8 11 13 6 113 212 112 233
0 11 -8 34 -18 50 -10 17 -47 84 -82 150 -34 66 -66 125 -70 130 -7 9 -56 101
-134 250 -17 33 -34 62 -37 65 -4 3 -21 33 -38 68 -17 34 -32 62 -34 62 -1 0
-7 -4 -13 -8z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
